<template>

  <div class="d-flex flex-column align-items-center">

    <p style="font-weight: bold; text-align: center; color: red;" v-if="error">{{ error }}</p>

    <div v-if="!swish_phonenumer_started">
      <b-form-group
        :label="$t('PUBLIC.PAYMENT.PAYER_ALIAS')">

        <b-form-input
          v-model="payer_alias"
        >
        </b-form-input>
      </b-form-group>
      
      <b-row align-h="center" class="mb-8 mt-8">
        <button :disabled="!payer_alias || payer_alias.length < 6" style="font-size: 22px; min-height: 35px; min-width: 280px;" class="btn btn-primary btn-lg mt-4" @click.prevent="create_swish_phonenumber_clicked()">{{$t('PUBLIC.PAYMENT.START_SWISH')}}</button>
        <p class="mt-4" style="text-align: center;">{{ $t('PUBLIC.PAYMENT.WRITE_YOUR_NUMBER') }}</p>
      </b-row>      
    </div>
    <div v-else>
      <div class="d-flex justify-content-center mb-8 mt-8">
        <span class="text-center">{{ $t('PUBLIC.PAYMENT.START_YOUR_SWISH_APP') }}</span>
      </div>

      <div class="d-flex justify-content-center mb-8 mt-8">
        <b-spinner label="Loading..."></b-spinner>
      </div>
    </div>

    <PaymentStatusComponent
      v-if="order && pending_payment"
      :order="order"
    />
  </div>
</template>

<script>
import axios from 'axios';
import PaymentComplete from '@/view/components/PaymentComplete.vue';
import PaymentOrderDetails from '@/view/components/PaymentOrderDetails.vue';
import PendingPaymentInfo from '@/view/components/PendingPaymentInfo.vue';
import PaymentStatusComponent from '@/view/pages/members/payment/PaymentStatusComponent.vue';


import { setKeyValue, getKeyValue } from '@/core/services/simpleStore';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';
import { is_mobile } from '@/core/services/utils';
import { get_domain_url, get_base_url, downloadWithAxios } from '@/core/services/fileDownload';

export default {
  name: 'SwishSubscriptionPaymentComponent',
  props: ['order','personal_details'],
  emits: ['updated', 'start_polling'],
  mixins: [ toasts ],
  components: {
    PaymentComplete,
    PaymentOrderDetails,
    PendingPaymentInfo,
    PaymentStatusComponent
  },
  computed: {
    
    callback_url() {
      return get_domain_url() + `/return-all-payment/${this.local_order.token}/${this.local_order.shop_order_id}`
    }

  },
  data() {
    return {
      swish_phonenumer_started: false,
      payer_alias: null,
      check_interval: null,
      image_found: false,
      pending_payment: false,

      local_order: null,
      error: null
    };
  },

  mounted() {

    /*
    First we need to create a consent, which we do via the consent endpoint

    */

    if (this.order) {
      this.local_order = { ...this.order };
    }
  },
  watch: {
    personal_details: {
      deep: true, // check properties of the form, not just the form reference (example.name for example)
      handler(val) {
        if (this.personal_details && this.personal_details.phone) {
          this.payer_alias = this.personal_details.phone;
          
          setTimeout(() => {
            this.create_payment_using_payer_alias();
          }, 500);
        }
      },
      immediate: true,
    },
    order: {
      deep: true, // check properties of the form, not just the form reference (example.name for example)
      handler(val) {
        this.local_order = { ...this.order };
      }
    },
  },
  methods: {

    create_swish_phonenumber_clicked() {
      /// make a payment request using phone (payer_alias)
      this.create_payment_using_payer_alias();
    },

    open_swish_phonenumber() {
      this.$refs['swish_phonenumber_modal'].show();
    },

    async create_payment_using_payer_alias() {
      try {
        const res = await axios.post(`/swish/merchant/consent/${this.local_order.shop_order_id}`, { payer_alias: this.payer_alias });

        if (res.status === 201) {
          this.swish_phonenumer_started = true;

          this.$emit('updated', res.data);
          this.$emit('start_polling');
          return true;
        }
        
      }
      catch (err) {
        console.error('create_payment_using_payer_alias error', err);
      }

      this.error = this.$t('PUBLIC.PAYMENT.SWISH_SUBSCRIPTION_ERROR');
      
    },


    get_callback_url() {
      if (!this.local_order || !this.local_order.swish_token) {
        console.log('callback url, this.order', this.local_order)
        return null;
      }

      return get_domain_url() + `/return-all-payment/${this.local_order.token}/${this.local_order.shop_order_id}`
    },

  }
};
</script>
